import MDinput from '@/components/MDinput';
import { validatorRequire } from '@/utils/validators';

export function getFormDefinition(vue) {
  return {
    id: {
      type: MDinput,
      props: {
        readonly: true
      }
    },
    name: {
      type: MDinput,
      defaultValue: '',
      props: {
        caption: 'dossier.reason',
        maxlength: 64,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    }
  };
}

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  const form = {
    ...item
  };
  return form;
}
